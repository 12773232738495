@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* Custom styles to remove arrows from number inputs */
.number-input {
  @apply appearance-none;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    @apply appearance-none m-0;
  }

  & {
    @apply appearance-none;
  }
}
